.photolist{
    display: flex;
    flex-direction: column;
    color: white;
    padding: 1rem;
    width: 80%;
    height: fit-content;
}
.groups{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 150px;
    width: 150px;
    padding: auto;
    font-size: larger;
    overflow: hidden;
    border-radius: 5px;
    background: hsla(0, 0%, 16%, 0.336);
    border: 1px solid hsla(0, 0%, 16%, 0.5);
    justify-content: space-between;
    margin: 5px;
    border: green;
}
.groups>img,.selected_groups>img{
    display: flex;
    max-width: 80%;
    max-height:80%;
    margin: auto;
}
.groups>p{
    margin: auto;
}
.group_list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
 

}
.list_toggle{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: xx-large;
    border-bottom:1px solid white;
}
.list_toggle svg:hover{
    cursor: pointer;
    background-color:  rgba(237, 241, 237, 0.233);
    color: black;
}
.selected_groups{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 150px;
    width: 150px;
    padding: auto;
    font-size: larger;
    overflow: hidden;
    border-radius: 5px;
    background: hsla(0, 0%, 16%, 0.336);
    border: 1px solid hsla(0, 0%, 16%, 0.5);
    justify-content: space-between;
    margin: 5px;
    background-color: hsla(0, 41%, 97%, 0.336);
    text-align: center;

}

.groups:hover,.selected_groups:hover{
    color: black;
    background-color: white;
    text-decoration: underline;
    cursor: pointer;


}

.slideshow{
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 80vw;
    height: auto;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin: auto;
    background: hsla(0, 0%, 16%, 0.336);
    border: 1px solid hsla(0, 0%, 16%, 0.5);
    border-radius: 5px;

}
.slideshow h2{
    color:  #afa7a7;
    text-align: left;
    padding-left: 0;

}
.selected_photo_container{
    display: flex;
    justify-self: center;
    justify-content: space-between;
    min-height: 400px;
    max-height: 60%;
    width: 100%;
    border-bottom: 1px solid white;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.selected_photo_container> img{
    margin: auto;
    width: auto;
    max-height: 90%;
    max-width: 90%;
} 
.selected_photo_container > div{
    display: flex;
    width: 5%;
    justify-content: center;
    align-content: center;
    padding: auto;

    

}
.selected_photo_container > div:hover{
    cursor: pointer;
}
.selected_photo_container p{
    display: flex;
    font-size: x-large;
    text-align: center;
    text-justify: center;
    color: white;
    margin: auto;
}
.photo_previews{
    width: 100%;
}
.photo_preview_container{
    display: flex;
    justify-self: center;
    width: 90%;
    height: auto;
    padding:10px;
    overflow-x: auto;
}
.photo_preview_container img{
    width: auto;
    height: 90px;
    margin: 5px;
}
.selected_photo_preview{
    border: 1px solid yellow;
    border-radius: 5px;
    padding: 0.25rem;
}
