.audio-player {
  display: flex;
  color: white;
  max-width: 1000px;
  min-width: 375px;
  width: fit-content;
  align-self: center;
  border-radius: 5px;
  margin: 1rem;
  margin-top: 4rem;
  background: hsla(0, 0%, 16%, 0.336);
  border: 1px solid hsla(0, 0%, 16%, 0.5);
  height: fit-content;
  min-height: 500px;
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;

}

/* display info */
.display-info {
  display: flex;
  flex-wrap: wrap ;
  width: 100%;
}
/* audio info */
.audio-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  gap:25px;
  width: 80%;
  align-items: center;

}

.text {
  min-width: 45%;
  color: white;
  background-color: rgba(0,0,0,.8);
  padding: 1em;
}

.title {
  color: #fff;
  font-weight: 100;
  font-size: 1.5rem;
  margin-bottom: 0;
  padding: 2px;
  line-height: 1.5rem;

}

/* audio image */
.icon-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  border: 1px solid red;
}

.audio-image {
  display: flex;
  justify-content: center;
  align-content: center;
  width: auto;
  height: auto;
  min-width: 150px;
  max-width: 300px;
  max-height: 300px;
  min-height: 150px;
 
  background: #1e1e1e; 
  border: 1px solid white;
}

.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #989898;
}

.audio-image img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

/* controls */
.controls-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.controls button, .volume button {
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

button svg {
  font-size: 20px;
}

.volume {
  display: flex;
  align-items: center;
}

.volume button {
  margin: 0;
}

.progress {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

/* time */
.time {
  color: #333;
  font-size: 13px;
  line-height: 46px;
}

.time.current {
  color: #f50;
}

/* Top bar */
.top__bar {
  font-size: 0.875rrem;
  line-height: 1.25rrem;
  text-align: center;
  padding-top: .5rrem;
  padding-bottom: .5rrem;
  background-image: linear-gradient(to left, rgba(203, 156, 49,0),rgba(203, 156, 49,100));

  border-bottom: 1px solid #f0f0f2;
}

.top__bar a {
  color: inherit;
}

.top__bar p {
  margin: 0;
}
/* TrackList */
.tracklist {
display: flex;
overflow-y: auto;
width: 100%;
border-right: 1px solid gray ;
height: 80%;

 flex-direction: column;
}
.selected_track {
  display: flex;
  background-color: rgba(244, 244, 244, 0.8);
  color: black;
  padding: 0.5rem;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  font-size: small;

}
.selected_track h3{
  font-size: medium;
}
.tracklist-image {
  display: flex;
  border: 0.5px solid white;
  width: 20%;
 
 
}
.tracklist-image img {
  width: 100%;
}
.tracklist-icon-wrapper{
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
}
.track{
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,.8);
  padding: 0.5rem;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  font-size: small;
  
}
.track:hover{
  background-color:  rgba(237, 241, 237, 0.233);;
}
.track h3{
  font-size: medium;
}
.track:hover,.selected_track:hover{
  cursor: pointer;
}
.tracklist-info{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
  align-items: center;
  border-radius: 5px;
  height: 95%;

}
.open_sidebar{
  display: flex;
  flex-direction: column;
  margin: 0;

  padding: 0;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
}
.tracklistHeader{
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  border-bottom: 1px solid rgba(237, 241, 237, 0.233);

}
.tracklistHeader>p{
  font-size: large;
  margin-left: 0.5rem;
}
.tracklistToggle{
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0.25rem;
  padding-top: 1rem;
 

}
.tracklistToggle:hover{
  cursor: pointer;
  background-color:  rgba(237, 241, 237, 0.233);
  color: black;
}
.listIcon{
  display: block;
  width: 25px;
  height: 25px;
}
.listIcon img{
  width: 24px;
}
.tracklist_mobile{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.track_mobile{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: rgba(0,0,0,.8);
  padding: 0.5rem;
  width: 100px;
  height: 75px;
  margin: 5px;
  font-size: small;

}
.selected_track_mobile{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: rgba(184, 184, 184, 0.8);
  padding: 0.5rem;
  width: 100px;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin: 5px;
  font-size: small;

}
.open_sidebar_mobile{
  width: 100%;
}
.tracklist-info-mobile{

}