.App {
  display: flex;
  position: relative;
  flex-direction: column;
  background-image: url("./imgs/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: fit-content;
  width: 100vw;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: fit-content;
}
