.topMenu {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100vw;
  height: fit-content;
  padding: 0;
  padding-bottom: 10px;
  color: #afa7a7;;
  align-content: center;
}
.menuItem a > li {
  list-style: none;
}
.menuItem p{
  color: #afa7a7;
  font-size: x-large;
}
.menuItem img,
.menuItem a > li img {
  display: flex;
  width: 50px;
}
.menuItem h2 {
  color: white;
}
.menuItem * a:hover {
  cursor: pointer;
  background-color: rgba(116, 107, 107, 0.253);
  color: white;
}
.menuToggle {
  width: fit-content;
  border: 1px solid #afa7a7;
  color: #afa7a7;
  background-color: #afa7a7;
  border-radius: 5px;
  justify-content: center;
}
.menuItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.menuContent {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: 0;
  height: fit-content;
  width: 100%;
  animation: fadeIn 2s;
  border-radius: 5px;
}

.menuContent li {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px dotted grey;
  font-size: 1.2rem;
  justify-content: space-evenly;
  color: #afa7a7;
}

.split a {
  height: 100%;
  width: 50%;
}
.youTube,
.facebook {
  border-right: 1px dotted grey;
}
