.contactForm{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 5px;
    background: hsla(0, 0%, 16%, 0.7);
    min-height: 30vw;
    max-height: 400px;
    width: 80%;
    max-width: 500px;
    color:#afa7a7;
}
.contactForm li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 90%;
    min-width: 150px;
    padding: 0.5rem;
}

.contactForm li input{
    all: unset;
    background-color: transparent;
    border-bottom: 1px solid #afa7a7;
    color: #afa7a7;
    font-size: 1rem;
    size: 80%;
    
}
.contactForm input[type=text]:focus {
    border-color: yellow;
}
.contactForm textarea{
    all: unset;
    border: 1px solid #afa7a7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-width: 50%;
    min-height: 100px;
}