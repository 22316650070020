@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body {
  background-color: black;
}
a {
  color: gray;
  text-decoration: none;
}
a:visited {
  all: unset;
}
nav {
  display: flex;
  justify-self: flex-end;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  overflow: hidden;
  margin-bottom: 5em;
  columns: white;
}
nav li {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 20%;
  background-image: linear-gradient(
    to right,
    #b17e178f,
    rgba(255, 255, 255, 0.418)
  );
  padding: 1em;
  margin: 1em;
  border-radius: 5px;
  min-width: 50px;
  color: white;
}
.home {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 40vw;
  width: 90vw;
  margin-top: 2em;
  align-items: center;
  justify-content: center;
}
.music {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content;
  padding-bottom: 5em;
  background-color: #3c3c3c;
  width: 100vw;
}
h1 {
  color: white;
}

.music_list {
  width: 70vw;
  height: 60vh;
  min-width: 400px;
}

.bio {
  display: inline-block;
  flex-wrap: wrap;
  width: 80vw;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  align-items: center;
}
.bio img {
  display: flex;
  flex-wrap: wrap;
  float: left;
  width: 40%;
  margin: 1em;
  min-width: 200px;
  max-width: 350px;
}

.bio p {
  font-size: 1.5em;
}
.mixes {
  display: flex;
  justify-content: center;
  align-content: center;
}
@media only screen and (max-width: 600px) {
}
